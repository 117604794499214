<template>
  <div class="bg-white h-full">
    <div class="p-4">
      <!-- <DepositForDoctors /> -->
      <DepositHistory />
    </div>
  </div>
</template>

<script>
import { HODO_CREDIT_TYPE } from '../..//utils/constants'
import DepositForDoctors from './CreditComponents/DepositForDoctors.vue'
import DepositHistory from './CreditComponents/DepositHistory.vue'

export default {
  name: 'DepositPoints',
  components: { DepositForDoctors, DepositHistory },
  data () {
    return {
      HODO_CREDIT_TYPE
    }
  }
}
</script>

<style lang="scss" scoped>
</style>